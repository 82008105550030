import authService from "@/services/authService"

export const authIframe = async (iframeWindow) => {
  const appAuth = {
    access_token: authService.getAccessToken(),
    authorities: authService.getRoles(),
    email: authService.getUserEmail(),
    expires_in: authService.getAccessTokenExpiration() * 1000,
    logged_in: authService.isLoggedIn(),
    refresh_token: authService.getRefreshToken(),
  }

  iframeWindow?.postMessage(
    {
      type: 'app_auth',
      app_auth: appAuth
    },
    '*'
  )
}

export const CHILD_REPORT_LOADED_MSG = "child_report_loaded"
export const APP_AUTH_MSG = "app_auth"
export const RESEND_AUTH_MSG = "resend_auth"